<template>
    <footer class="footer">
      <div class="footer-content">
        <div class="footer-links">
          <router-link to="/" class="footer-link">{{ $t('accueil') }}</router-link>
          <router-link to="/vehicles" class="footer-link">{{ $t('voitures') }}</router-link>
          <router-link to="/about" class="footer-link">{{ $t('apropos') }}</router-link>
          <router-link to="/contact" class="footer-link">{{ $t('contact') }}</router-link>
        </div>
        <div class="footer-map-container">
          <div class="footer-map-box">
            <InteractiveMap />
          </div>
        </div>
        <div class="footer-social-media">
          <a href="tel:+2120687350555" class="social-button phone" aria-label="Phone">
            <img src="@/assets/phone.png" alt="Phone Icon" class="social-icon phone-icon" />
            <span class="social-text">{{ $t('telephone') }}</span>
          </a>
          <a href="https://wa.me/2120687350555" class="social-button whatsapp" aria-label="WhatsApp">
            <img src="@/assets/sociale.png" alt="WhatsApp Icon" class="social-icon whatsapp-icon" />
            <span class="social-text">WhatsApp</span>
          </a>
          <a href="https://instagram.com/scorpiocar.ma" target="_blank" class="social-button instagram" aria-label="Instagram">
            <img src="@/assets/instagram.png" alt="Instagram Icon" class="social-icon instagram-icon" />
            <span class="social-text">Instagram</span>
          </a>
        </div>

        <!-- Liens légaux -->
        <div class="footer-legal-links">
          <router-link to="/privacy-policy" class="legal-link">
            {{ $t('legalLinks.privacyPolicy') }}
          </router-link>
          <router-link to="/legal-notice" class="legal-link">
            {{ $t('legalLinks.legalNotice') }}
          </router-link>
          <router-link to="/terms-of-service" class="legal-link">
            {{ $t('legalLinks.termsOfService') }}
          </router-link>
        </div>


        <!-- Footer Copyright and Author -->
        <div class="footer-copyright">
          <div class="footer-logo">
            <!-- Placeholder for logo; replace with actual logo once available -->
            <img src="@/assets/logo.png" alt="Scorpiocar Logo" class="footer-logo-image" />
          </div>
          <div class="footer-text">
            © {{ new Date().getFullYear() }} Scorpiocar Casablanca. All rights reserved.
        </div>
          <div class="footer-author">
            Site created by <span class="author-name">Disarray</span>
          </div>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  import InteractiveMap from './InteractiveMap.vue';
  
  export default {
    name: 'FooterPage',
    components: {
      InteractiveMap
    }
  };
  </script>
  
  <style scoped>
  /* Include font definitions */
 /* Styles pour le footer */
.footer {
  background-color: #000000; /* Fond noir */
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  
}

/* Contenu du footer */
.footer-content {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Liens du footer */
.footer-links {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  position: relative;
  z-index: 10;
}

.footer {
  border: none; /* Remove border if not needed */
}

.footer-link {
  color: #ffffff; /* Texte blanc */
  text-decoration: none;
  font-size: 1.2rem; /* Taille de police augmentée pour plus de lisibilité */
  font-weight: 500; /* Poids de police légèrement plus gras */
  transition: color 0.3s ease, text-shadow 0.3s ease; /* Transition pour le texte lumineux */
}

/* Effet visuel au survol pour les liens de footer */
.footer-link:hover, 
.social-button:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Ombre portée légère */
  transform: scale(1.05); /* Légère augmentation de la taille de l'élément */
}

/* Conteneur pour la carte */
.footer-map-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  position: relative;
}

.footer-map-box {
  width: 100%;
  max-width: 800px;
  height: 300px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  z-index: 1;
}

/* Legal links */
.footer-legal-links {
  margin-top: 1rem; /* Add space between social media buttons and legal links */
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  justify-content: center;
}

.legal-link {
  color: #999999; /* Lighter color for legal links */
  text-decoration: none;
  font-size: 0.85rem; /* Smaller font size for legal links */
  transition: color 0.3s ease;
}

.legal-link:hover {
  color: #cccccc; /* Slightly lighter color on hover */
}


/* Médias sociaux */
.footer-social-media {
  display: flex;
  gap: 1.5rem;
  margin-top: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.social-button {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: #C8102E; /* Rouge vif */
  border-radius: 50px;
  color: white;
  text-decoration: none;
  transition: background-color 0.3s ease;
  width: auto;
  max-width: 200px;
  font-family: 'Poppins', sans-serif;
}

.social-button:hover {
  background-color: #B71C1C; /* Rouge plus foncé */
  animation: vibrate 0.5s ease-in-out;
}

@keyframes vibrate {
  0% { transform: translateX(0); }
  25% { transform: translateX(-3px); }
  50% { transform: translateX(3px); }
  75% { transform: translateX(-3px); }
  100% { transform: translateX(0); }
}

.social-icon {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.social-text {
  font-size: 14px;
  color: #ffffff;
}

/* Copyright et Author */
.footer-copyright {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.footer-logo {
  margin-bottom: 0.5rem;
}

.footer-logo-image {
  width: 120px; /* Ajuster la largeur selon les besoins */
  height: auto;
}

.footer-text {
  color: #6c757d;
  font-size: 0.875rem;
}

.footer-author {
  color: #6c757d;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.author-name {
  font-family: 'Ramadhan', sans-serif; /* Utiliser 'Ramadhan' pour le nom de l'auteur */
  font-weight: bold;
  color: #C8102E; /* Rouge vif */
  font-size: 1.2rem;
  letter-spacing: 0.1rem; /* Espacement entre les lettres */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Ombre subtile */
}

/* Responsive styling */
@media (max-width: 600px) {
  .footer-links {
    flex-direction: column;
    gap: 0.5rem;
  }

  .footer-social-media {
    gap: 1rem;
  }

  .social-button {
    padding: 8px 10px;
    max-width: none;
  }

  .social-icon {
    width: 24px;
    height: 24px;
  }

  .social-text {
    font-size: 12px;
  }

  .footer-legal-links {
    flex-direction: column;
    gap: 0.25rem;
  }
}



  </style>
  