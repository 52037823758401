<template>
  <div id="app">
    <Header />
    <router-view />
    <FooterPage />
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';
import { watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n'; 
import Header from './components/MainHeader.vue';
import FooterPage from './components/FooterPage.vue';

export default {
  name: 'App',
  components: {
    Header,
    FooterPage
  },
  setup() {
    const route = useRoute();
    const { locale, t } = useI18n();

    // Appliquer la langue stockée au démarrage
    onMounted(() => {
      const storedLocale = localStorage.getItem('app_locale');
      if (storedLocale) {
        locale.value = storedLocale;
      }
    });

    // Mettre à jour le titre de la page à chaque changement de route
    watch(
      () => route.meta.title,
      (newTitle) => {
        useHead({
          title: `Scorpiocar | ${t(`pageTitles.${newTitle}`) || t('defaultTitle')}` // Utilisation de la traduction
        });
      },
      { immediate: true } // Applique immédiatement à la première visite
    );

    // Mettre à jour la langue dans le localStorage chaque fois qu'elle change
    watch(
      () => locale.value,
      (newLocale) => {
        localStorage.setItem('app_locale', newLocale);
      }
    );
  }
};
</script>

<style>
/* Import de Poppins depuis Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  
  
}

body {
  margin: 0;
}

/* Polices personnalisées */
@font-face {
  font-family: 'Aljawi';
  src: url('@/assets/Aljawi.otf') format('opentype');
}

@font-face {
  font-family: 'Alladhina';
  src: url('@/assets/Alladhina.otf') format('opentype');
}

@font-face {
  font-family: 'Ramadhan';
  src: url('@/assets/Ramadhan.otf') format('opentype');
}
</style>
