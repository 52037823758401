import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createHead } from '@vueuse/head';
import { createI18n } from 'vue-i18n';

// Leaflet CSS
import 'leaflet/dist/leaflet.css';

// Your locale imports
import fr from './locales/fr.json';
import en from './locales/en.json';
import ar from './locales/ar.json';

// Get stored locale or fallback to 'fr'
const storedLocale = localStorage.getItem('app_locale') || 'fr';

// Create i18n instance
const i18n = createI18n({
  legacy: false,
  locale: storedLocale,
  fallbackLocale: 'fr',
  messages: {
    fr,
    en,
    ar
  }
});

// Create app instance
const app = createApp(App);

// Use other plugins
app.use(router);
app.use(createHead());
app.use(i18n);

// Mount the app
app.mount('#app');
