<template>
  <div class="about-page">
    <header class="about-header">
      <h1>{{ $t('aboutPage.title') }}</h1>
    </header>
    <section class="about-content">
      <div class="about-section">
        <h2>{{ $t('aboutPage.missionTitle') }}</h2>
        <p>{{ $t('aboutPage.missionText') }}</p>
      </div>
      <div class="about-section">
        <h2>{{ $t('aboutPage.aboutTitle') }}</h2>
        <p>{{ $t('aboutPage.aboutText1') }}</p>
        <p>{{ $t('aboutPage.aboutText2') }}</p>
        <p>{{ $t('aboutPage.aboutText3') }}</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "AboutPage",
};
</script>

<style scoped>
.about-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #111; /* Fond noir pour la page */
  padding: 20px;
  color: #fff; /* Texte blanc pour une bonne lisibilité */
}

.about-header {
  margin-bottom: 20px;
}

.about-header h1 {
  font-size: 2.5em;
  color: #fff; /* Texte blanc pour le titre principal */
  text-align: center;
}

.about-content {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.about-section {
  background-color: #222; /* Fond sombre pour les sections */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Ombre plus marquée pour les sections */
}

.about-section h2 {
  font-size: 1.8em;
  color: #c00; /* Rouge pour les sous-titres */
  margin-bottom: 10px;
}

.about-section p {
  font-size: 1em;
  color: #ccc; /* Texte gris clair pour une bonne lisibilité sur fond sombre */
  line-height: 1.6;
}

.about-footer {
  margin-top: 40px;
  text-align: center;
  width: 100%;
  padding: 10px 0;
  background-color: #111; /* Fond noir pour le footer */
  color: #fff; /* Texte blanc pour le footer */
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.footer-logo-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  border-radius: 8px;
  background-color: #c00; /* Rouge pour le placeholder du logo */
  color: #fff; /* Texte blanc pour le placeholder du logo */
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
}
</style>
