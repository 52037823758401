<template>
  <header>
    <nav class="container">
      <div class="logo">
        <!-- Utilisation de router-link pour rediriger vers la route racine -->
        <router-link to="/">
          <img :src="require('@/assets/logo.png')" alt="Scorpiocar" />
        </router-link>
      </div>
      <ul class="menu" :class="{ 'active': menuOpen }">
        <li>
          <router-link
            to="/"
            class="nav-link"
            active-class="active-link"
            @click="closeMenu"
            >{{ $t('accueil') }}</router-link
          >
        </li>
        <li>
          <router-link
            to="/vehicles"
            class="nav-link"
            active-class="active-link"
            @click="closeMenu"
            >{{ $t('voitures') }}</router-link
          >
        </li>
        <li>
          <router-link
            to="/about"
            class="nav-link"
            active-class="active-link"
            @click="closeMenu"
            >{{ $t('apropos') }}</router-link
          >
        </li>
        <li>
          <router-link
            to="/contact"
            class="nav-link contact-link"
            active-class="active-link"
            @click="closeMenu"
            >{{ $t('contacteznous') }}</router-link
          >
        </li>
        <li class="lang-dropdown">
          <button class="dropdown-toggle" @click="toggleLangDropdown">
            <img
              :src="getFlag(selectedLocale)"
              alt="Drapeau"
              class="flag-icon"
            />
            {{ $t(selectedLocale) }} <span class="arrow"></span>
          </button>
          <ul class="dropdown-menu" v-show="langDropdownOpen">
            <li v-for="locale in availableLocales" :key="locale">
              <button @click="setLocale(locale)">
                <img :src="getFlag(locale)" alt="Drapeau" class="flag-icon" />
                {{ $t(locale) }}
              </button>
            </li>
          </ul>
        </li>
      </ul>
      <button class="menu-toggle" @click="toggleMenu" aria-label="Open menu">
        <span class="bar" :class="{ rotate1: menuOpen }"></span>
        <span class="bar" :class="{ fade: menuOpen }"></span>
        <span class="bar" :class="{ rotate2: menuOpen }"></span>
      </button>
    </nav>
  </header>
</template>

<script>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const i18n = useI18n();
    const selectedLocale = computed(() => i18n.locale.value);
    const availableLocales = ['fr', 'ar', 'en'];

    const menuOpen = ref(false);
    const langDropdownOpen = ref(false);

    const setLocale = (locale) => {
      i18n.locale.value = locale;
      toggleLangDropdown(); // Fermer le menu déroulant après avoir sélectionné la langue
      closeMenu(); // Fermer le menu après avoir défini la langue
    };

    const getFlag = (locale) => {
      return require(`@/assets/flags/${locale}.png`);
    };

    const toggleMenu = () => {
      menuOpen.value = !menuOpen.value;
      langDropdownOpen.value = false; // Fermer le menu déroulant si le menu est ouvert
    };

    const closeMenu = () => {
      menuOpen.value = false;
    };

    const toggleLangDropdown = () => {
      langDropdownOpen.value = !langDropdownOpen.value;
    };

    return {
      selectedLocale,
      availableLocales,
      setLocale,
      getFlag,
      toggleMenu,
      closeMenu,
      toggleLangDropdown,
      menuOpen,
      langDropdownOpen,
    };
  },
};
</script>

<style scoped>
/* Styles pour le header */
header {
  background-color: #000000; /* Fond noir */
  padding: 0.5rem 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Styles pour le conteneur du header */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Styles pour le logo */
.logo {
  margin-right: auto;
}

.logo img {
  max-height: 9rem;
  width: auto;
  display: block;
}

/* Styles pour le menu */
.menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: none; /* Caché par défaut sur mobile */
  align-items: center;
}

.menu.active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.9); /* Fond sombre */
  z-index: 10;
  transform: scale(0);
  animation: showMenu 0.5s forwards;
}

@keyframes showMenu {
  to {
    transform: scale(1);
  }
}

/* Liens du menu */
.nav-link {
  display: block;
  padding: 1rem;
  color: #ffffff; /* Texte blanc */
  text-decoration: none;
  font-size: 1.2rem; /* Taille de police augmentée pour plus de lisibilité */
  font-weight: 500; /* Poids de police légèrement plus gras */
  text-align: center;
  transition: color 0.3s ease, text-shadow 0.3s ease; /* Effet de transition pour le texte lumineux */
}

.nav-link:hover {
  color: #ffffff; /* Texte blanc lumineux */
  text-shadow: 0 0 8px #ffffff; /* Effet lumineux blanc */
}

.nav-link.active-link {
  color: #C8102E; /* Rouge vif pour l'état actif */
  font-weight: bold; /* Gras pour l'état actif */
  text-shadow: none; /* Pas de texte lumineux pour l'état actif */
}

/* Mise en valeur du lien Contact */
.contact-link {
  padding: 0.5rem 1rem;
  border: 2px solid #C8102E; /* Bordure rouge */
  border-radius: 5px;
  color: #C8102E; /* Rouge vif */
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-left: 1rem;
}

.contact-link:hover,
.contact-link.active-link {
  background-color: #B71C1C; /* Rouge plus foncé */
  color: #ffffff; /* Texte blanc */
  box-shadow: none; /* Pas d'effet lumineux */
}

/* Styles pour le bouton hamburger */
.menu-toggle {
  display: block;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 11;
  margin-left: auto;
  margin-right: 20px;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #ffffff; /* Barres blanches */
  margin: 5px 0;
  transition: all 0.3s ease-in-out;
}

/* Animation pour la transformation en croix */
.rotate1 {
  transform: rotate(45deg) translate(5px, 5px);
}

.rotate2 {
  transform: rotate(-45deg) translate(5px, -5px);
}

.fade {
  opacity: 0;
}

/* Styles pour les écrans plus grands */
@media (min-width: 768px) {
  .menu {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    position: static;
    height: auto;
    background-color: transparent;
    box-shadow: none;
  }

  .menu-toggle {
    display: none; /* Masquer le bouton hamburger sur les grands écrans */
  }

  .nav-link {
    font-size: 1rem; /* Taille de police normale */
    color: #ffffff; /* Texte blanc */
  }
}

/* Styles pour le dropdown langue */
.lang-dropdown {
  position: relative;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  color: #ffffff; /* Texte blanc */
  text-transform: uppercase; /* Ajout de la transformation en majuscules */

}

/* Effet visuel au survol */
.dropdown-toggle:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Ombre portée légère */
  transform: scale(1.05); /* Légère augmentation de la taille du bouton */
}

.flag-icon {
  width: 32px;
  height: 24px;
  margin-right: 0.5rem;
  object-fit: contain;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #333333; /* Changer la couleur de fond en gris foncé */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Ombre plus marquée */
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 1000;
  border-radius: 4px; /* Coins arrondis pour un effet plus doux */
  
}

.dropdown-menu button {
  color: #ffffff; /* Texte blanc pour une bonne lisibilité */
  
}

.dropdown-menu button:hover {
  background-color: #555555; /* Fond gris moyen au survol */
  color: #ffffff; /* Assurer que le texte reste blanc au survol */
}

.dropdown-menu li button {
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase; /* Ajout de la transformation en majuscules */

}

.dropdown-menu li:last-child {
  border-bottom: none;
}

.dropdown-menu button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #000000; /* Texte noir */
  text-align: left;
  transition: background-color 0.3s ease;
}

.nav-link:hover, 
.dropdown-toggle:hover,
.dropdown-menu button:hover {
   /* Changement léger de la couleur de fond */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Ombre portée légère */
  transform: scale(1.05); /* Légère augmentation de la taille de l'élément */
}

.arrow {
  display: inline-block;
  margin-left: 0.5rem;
  transition: transform 0.3s ease;
}

.lang-dropdown.open .arrow {
  transform: rotate(180deg);
}

/* Styles spécifiques pour les écrans mobiles */
@media (max-width: 767px) {
  .nav-link {
    font-size: 1.5rem; /* Augmenter la taille de police pour plus de lisibilité */
    padding: 1.5rem; /* Augmenter le padding pour plus d'espace cliquable */
    font-weight: 600; /* Poids de police légèrement plus gras */
  }

  .contact-link {
    padding: 1rem 1.5rem; /* Augmenter le padding pour plus d'espace */
  }

  .dropdown-toggle {
    font-size: 1rem; /* Augmenter la taille de police pour plus de lisibilité */
  }

  .dropdown-menu button {
    font-size: 1rem; /* Augmenter la taille de police pour plus de lisibilité */
  }
}
</style>

