import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://django.scorpiocar.com/api',
});

apiClient.interceptors.request.use(config => {
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

apiClient.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response && error.response.status === 401) {
    // Le token a probablement expiré ou est invalide
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  }
  return Promise.reject(error);
});

export default apiClient;
