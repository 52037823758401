<template>
  <div class="home-container">
    <h1>
      {{ $t('location') }}
      <span @click="goToAllVehicles">{{ $t('seeAll') }}</span>
    </h1>
    <swiper
      :slidesPerView="slidesPerView"
      :spaceBetween="20"
      :loop="true"
      :pagination="{
        clickable: true,
        type: 'bullets',
        dynamicBullets: true
      }"
      :navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }"
      :autoplay="{
        delay: 3000,
        disableOnInteraction: false
      }"
      :modules="modules"
      @beforeInit="beforeInit"
      class="mySwiper"
    >
      <swiper-slide v-for="vehicle in vehicles" :key="vehicle.id" class="swiper-slide">
        <div class="vehicle-card">
          <img :src="vehicle.image" alt="Vehicle Image" class="vehicle-image">
          <div class="vehicle-info">
            <h2>{{ vehicle.brand }} {{ vehicle.model }}</h2>
            <div class="price-info">
              <p>
                <strong>{{ $t('startingFrom') }}</strong>
                <span></span>
              </p>
            </div>
            <div class="contact-info">
              <a
                :href="'https://wa.me/2120687350555?text=' + encodeURIComponent($t('whatsappMessage', { vehicleName: `${vehicle.brand} ${vehicle.model}`, vehicleColor: $t(`colors.${vehicle.color}`) }))"
                class="contact-icon whatsapp"
              >
                <img src="@/assets/sociale.png" alt="WhatsApp Icon" class="contact-icon-image" />
              </a>
              <a
                :href="'mailto:info@scorpiocar.com?subject=' + encodeURIComponent($t('emailSubject', { vehicleName: `${vehicle.brand} ${vehicle.model}`, vehicleColor: $t(`colors.${vehicle.color}`) })) + '&body=' + encodeURIComponent($t('emailBody', { vehicleName: `${vehicle.brand} ${vehicle.model}`, vehicleColor: $t(`colors.${vehicle.color}`) }))"
                class="contact-icon email"
              >
                <img src="@/assets/email.png" alt="Email Icon" class="contact-icon-image" />
              </a>
            </div>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
      <div class="swiper-pagination"></div>
    </swiper>
  </div>
</template>



<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import axios from 'axios';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    const slidesPerView = ref(3);

    const updateSlidesPerView = () => {
      if (window.innerWidth <= 768) {
        slidesPerView.value = 1;
      } else {
        slidesPerView.value = 3;
      }
    };

    onMounted(() => {
      updateSlidesPerView();
      window.addEventListener('resize', updateSlidesPerView);
    });

    const beforeInit = (swiper) => {
      swiper.params.loop = false;
      swiper.params.pagination.renderBullet = (index, className) => {
        return `<span class="${className}"></span>`;
      };
    };

    const router = useRouter();

    const goToAllVehicles = () => {
      router.push('/vehicles');
    };

    return {
      slidesPerView,
      modules: [Pagination, Navigation, Autoplay],
      beforeInit,
      goToAllVehicles
    };
  },
  data() {
    return {
      vehicles: []
    };
  },
  mounted() {
    axios.get('https://django.scorpiocar.com/api/vehicles/?format=json')
      .then(response => {
        this.vehicles = response.data;
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }
};
</script>


<style scoped>
.home-container {
  padding: 20px;
  text-align: center;
  background-color: #111; /* Fond noir pour la section d'accueil */
  color: #fff; /* Texte blanc pour le contraste */
}


h1 {
  margin-bottom: 20px;
  font-size: 2.5em;
  font-weight: bold;
  text-align: left;
  color: #fff; /* Texte blanc pour les titres */
}

h1 span {
  float: right;
  font-size: 1em;
  color: #c00; /* Rouge pour les éléments interactifs dans le titre */
  cursor: pointer;
  transition: color 0.3s, text-shadow 0.3s; /* Ajout de transitions pour les effets de survol */
  
}

h1 span:hover {
  color: #e00; /* Couleur plus claire ou différente pour l'effet de survol */
  text-shadow: 0 0 4px rgba(255, 0, 0, 0.7); /* Ajout d'une ombre légère au survol pour plus de visibilité */
  transform: scale(1.05); /* Zoom légèrement le texte au survol */
}

.mySwiper {
  width: 90%;
  margin: 0 auto;
  padding: 20px 0;
  position: relative;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vehicle-card {
  width: 100%;
  max-width: 300px;
  background-color: #333; /* Fond sombre pour les cartes de véhicule */
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.vehicle-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.vehicle-info {
  padding: 15px;
  text-align: center;
}

.vehicle-info h2 {
  margin: 10px 0;
  font-size: 1.2em;
  color: #c00; /* Rouge pour les sous-titres dans les cartes de véhicule */
}

.price-info {
  margin: 10px 0;
  color: #fff; /* Texte blanc pour les prix */
}

.price-info p {
  margin: 5px 0;
}

.contact-info {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.contact-icon {
  margin: 0 10px;
  text-decoration: none;
  transition: transform 0.3s, color 0.3s;
}

.contact-icon.email{
  filter: invert(100%) brightness(100%) contrast(100%);
}

.contact-icon:hover {
  transform: scale(1.1);
}

.contact-icon-image {
  width: 30px;
  height: 30px;
}

/* WhatsApp Icon */
.contact-icon.whatsapp {
  color: #25D366; /* Couleur de l'icône WhatsApp */
}

/* Email Icon */
.contact-icon.email {
  color: #D44638; /* Couleur de l'icône Email */
}

.swiper-button-prev,
.swiper-button-next {
  color: #c00; /* Rouge pour les boutons de navigation du swiper */
  position: absolute;
  top: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  z-index: 10;
  cursor: pointer;
}

@media (max-width: 768px) {
  .swiper-button-prev {
    display: none;
  }

  .swiper-button-next {
    display: none;
  }
}

.swiper-pagination-bullet {
  background-color: red !important; /* Red for inactive bullets */
  opacity: 0.5;       /* Slightly transparent when inactive */
}

.swiper-pagination-bullet-active {
  background-color: red !important; /* Red for the active bullet */
  opacity: 1;            /* Fully opaque when active */
}

/* Deep Selectors (For Swiper elements) */
:deep(.swiper-pagination-bullet) {
  background-color: red !important;
  opacity: 0.5;
}

:deep(.swiper-pagination-bullet-active) {
  background-color: red !important;
  opacity: 1;
}
</style>
