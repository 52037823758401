<template>
  <div class="contact-page">
    <main>
      <section class="contact-info">
        <div class="contact-details">
            <div class="contact-item">
              <img src="@/assets/phone.png" alt="Phone Icon" class="contact-icon" />
              <p>+212 6 87 35 05 55</p>
            </div>
            <div class="contact-item">
              <img src="@/assets/email.png" alt="Email Icon" class="contact-icon" />
              <p>info@scorpiocar.com</p>
            </div>
            <div class="contact-item">
              <img src="@/assets/location.png" alt="Location Icon" class="contact-icon" />
              <p>106 Lot Ennahda MG 18, Sidi Moumen, Casablanca</p>
            </div>
            </div>
        </section>

      <div class="contact-form">
        <h2>{{ $t('requestContact') }}</h2>
        <form @submit.prevent="submitForm">
          <div class="form-group">
            <label for="name">{{ $t('name') }}</label>
            <input type="text" id="name" v-model="form.name" required />
            <span v-if="!form.name && attemptedSubmit" class="error-message">{{ $t('nameIsRequired') }}</span> 
          </div>
          <div class="form-group">
            <label for="phone">{{ $t('phoneNumber') }}</label>
            <input id="phone" type="tel" v-model="form.phone" placeholder="+212 1 23 45 67 89" />
            <span v-if="!isPhoneNumberValid" class="error-message">{{ $t('invalidPhoneNumber') }}</span>
          </div>
          <div class="form-group">
            <label for="email">{{ $t('email') }}</label>
            <input type="email" id="email" v-model="form.email" required />
            <span v-if="form.email && !isEmailValid" class="error-message">{{ $t('invalidEmail') }}</span>
            <span v-else-if="!form.email && attemptedSubmit" class="error-message">{{ $t('emailIsRequired') }}</span> 
          </div>
          <div class="form-group">
            <label for="message">{{ $t('message') }}</label>
            <textarea id="message" v-model="form.message" required></textarea>
            <span v-if="!form.message && attemptedSubmit" class="error-message">{{ $t('messageIsRequired') }}</span> 
          </div>
          <button type="submit" class="submit-button">{{ $t('send') }}</button> 
          <div v-if="isSubmitting" class="spinner"></div>
          <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
          <div v-if="showErrorMessage" class="error-message">
            {{ $t('pleaseFillAllFields') }} 
          </div> 
        </form>
      </div>
    </main>
    <div class="watermark">
      </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      form: {
        name: "",
        phone: "",
        email: "",
        message: ""
      },
      successMessage: "",
      isSubmitting: false,
      errors: {}  // Object to store validation errors
    };
  },
  computed: {
    isEmailValid() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(this.form.email);
    },
    isPhoneNumberValid() {
      const phonePattern = /^\+?\d{10,}$/;
      return this.form.phone === "" || phonePattern.test(this.form.phone);
    },
    isFormValid() {
      return this.isEmailValid && this.isPhoneNumberValid && this.form.name && this.form.message;
    }
  },
  methods: {
    validateForm() {
      const errors = {};
      if (!this.form.name) errors.name = this.$t('nameIsRequired');
      if (!this.form.email) errors.email = this.$t('emailIsRequired');
      else if (!this.isEmailValid) errors.email = this.$t('invalidEmail');
      if (!this.form.phone || !this.isPhoneNumberValid) errors.phone = this.$t('invalidPhoneNumber');
      if (!this.form.message) errors.message = this.$t('messageIsRequired');
      
      this.errors = errors;
      return Object.keys(errors).length === 0;
    },
    submitForm() {
      // Reset errors before validation
      this.errors = {};

      if (this.validateForm()) {
        this.isSubmitting = true;
        axios.post('https://django.scorpiocar.com/api/contact/', this.form)
          .then(() => {
            this.successMessage = this.$t('messageSentSuccess');
            this.form = { name: "", phone: "", email: "", message: "" };
            setTimeout(() => {
              this.successMessage = "";
            }, 5000);
          })
          .catch(error => {
            console.error("Il y a eu une erreur lors de l'envoi du message :", error);
            alert("Une erreur est survenue. Veuillez réessayer.");
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    }
  }
};
</script>

  <style scoped>
 .contact-page {
  position: relative;
  padding-top: 60px; /* Compense pour la hauteur de l'en-tête fixe */
  background-color: #000; /* Fond noir pour la page */
  color: #fff; /* Texte blanc pour une bonne lisibilité */
}

main {
  padding: 20px;
  background-color: #111; /* Fond très sombre pour le contenu principal */
}

.contact-info {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.contact-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
}

.contact-item {
  display: flex;
  flex-direction: column; /* Alignement vertical pour mobile */
  align-items: center; /* Centre horizontalement */
  text-align: center; /* Centre le texte */
  color: #fff; /* Texte blanc pour les éléments de contact */
  margin: 10px 0;
}

.contact-icon {
  width: 24px;
  height: 24px;
  margin-bottom: 10px; /* Espace entre l'icône et le texte */
  fill: #fff; /* Icônes blanches pour ressortir sur le fond sombre */
  filter: invert(100%) brightness(100%) contrast(100%);
}

.contact-form {
  background-color: #1a1a1a; /* Fond très sombre pour le formulaire */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6); /* Ombre plus marquée pour le formulaire */
  max-width: 600px; /* Ajusté la largeur du formulaire */
  margin: 0 auto; /* Centrer le formulaire */
}

.contact-form h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #c00; /* Rouge pour le titre du formulaire */
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #fff; /* Texte blanc pour les labels */
}

input, textarea {
  font-size: 16px; /* Taille de la police à 16px */
  width: calc(100% - 20px); /* Ajuste la largeur pour le padding */
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #555; /* Bordure grise foncée pour les champs de formulaire */
  background-color: #333; /* Fond sombre pour les champs de formulaire */
  color: #fff; /* Texte blanc pour les champs de formulaire */
}

input[type="tel"] {
  width: 100%;
  font-size: 16px; /* Taille de la police correcte */
  padding: 10px;
  box-sizing: border-box; /* Inclut le padding et la bordure dans la largeur totale */
}

textarea {
  height: 100px;
  resize: vertical;
}

.submit-button {
  background-color: #c00; /* Rouge pour le bouton de soumission */
  color: #fff;
  border: none;
  padding: 12px 24px;
  font-size: 1.1em;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  font-family: 'Poppins', sans-serif;
}

.submit-button:hover {
  background-color: #a00; /* Rouge foncé pour l'état survolé du bouton */
  transform: translateY(-3px);
}

.success-message {
  color: #0f0; /* Vert pour le message de succès */
  font-size: 1em;
  margin-top: 10px;
  font-weight: bold;
}

.error-message {
  color: #f00; /* Rouge pour les messages d'erreur */
  font-size: 0.9em;
  margin-top: 5px;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3); /* Fond semi-transparent */
  border-radius: 50%;
  border-top: 4px solid #f00; /* Couleur de la barre de chargement */
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.watermark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fond noir semi-transparent pour la marque d'eau */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1; /* Derrière le contenu */
}

.watermark-content {
  text-align: center;
  color: #fff;
  font-size: 2em;
  font-weight: bold;
}

@media (max-width: 768px) {
  .contact-details {
    display: flex; /* Utilisation de flexbox pour les écrans plus petits */
    flex-direction: column; /* Affiche les éléments de contact en colonne */
    align-items: center; /* Centre les éléments horizontalement */
    gap: 20px; /* Espace entre les éléments de contact */
  }

  .contact-item {
    display: flex;
    flex-direction: column; /* Texte sous l'icône */
    align-items: center; /* Centre horizontalement */
    text-align: center; /* Centre le texte */
  }

  .contact-icon {
    margin-bottom: 10px; /* Espacement entre icône et texte */
  }
}

  </style>