<template>
    <div>
      <h1>Administration des Véhicules</h1>
      <!-- Liste des véhicules avec options de modification -->
      <div v-for="vehicle in vehicles" :key="vehicle.id">
        <p>{{ vehicle.brand }} {{ vehicle.model }}</p>
        <router-link :to="`/admin/vehicles/edit/${vehicle.id}`">Modifier</router-link>
      </div>
      <router-link to="/admin/vehicles/new">Ajouter un nouveau véhicule</router-link>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        vehicles: []
      };
    },
    mounted() {
      axios.get('https://django.scorpiocar.com/api/vehicles/')
        .then(response => {
          this.vehicles = response.data;
        })
        .catch(error => {
          console.error('Erreur lors de la récupération des véhicules', error);
        });
    }
  };
  </script>
  