<template>
    <div class="login-page">
      <main>
        <section class="login-form-container">
          <h1>{{ $t('login') }}</h1>
  
          <form @submit.prevent="login" class="login-form">
            <div class="form-group">
              <label for="username">{{ capitalizeFirstLetter($t('username')) }}</label>
              <div class="input-wrapper">
                <img src="@/assets/avatar.png" alt="Username Icon" class="input-icon" />
                <input v-model="username" id="username" required placeholder=" " />
              </div>
            </div>
            <div class="form-group">
              <label for="password">{{ capitalizeFirstLetter($t('password')) }}</label>
              <div class="input-wrapper">
                <img src="@/assets/lock.png" alt="Password Icon" class="input-icon" />
                <input
                  :type="passwordFieldType"
                  v-model="password"
                  id="password"
                  required
                  placeholder=" "
                />
                <span class="icon" @click="togglePasswordVisibility">
                  <i :class="passwordVisible ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
                </span>
              </div>
            </div>
            <button type="submit" class="submit-button" :disabled="!username || !password">{{ $t('login') }}</button>
            <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
          </form>
        </section>
      </main>
      <div class="watermark">
        <div class="watermark-content">
          <h1>{{ $t('login') }}</h1>
          <p>{{ $t('pleaseLogin') }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
import apiClient from '@/axios';

export default {
  data() {
    return {
      username: '',
      password: '',
      errorMessage: '',
      passwordVisible: false,
    };
  },
  computed: {
    passwordFieldType() {
      return this.passwordVisible ? 'text' : 'password';
    }
  },
  methods: {
    async login() {
      try {
        // Appel à l'API pour obtenir les tokens
        const response = await apiClient.post('/token/', {
          username: this.username,
          password: this.password,
        });

        // Stocker les tokens dans le localStorage
        localStorage.setItem('access_token', response.data.access);
        localStorage.setItem('refresh_token', response.data.refresh);

        // Attendre la prochaine mise à jour du DOM avant de rediriger
        await this.$nextTick();

        // Rediriger vers la page des véhicules administratifs
        this.$router.push('/admin/vehicles');
      } catch (error) {
        // Gestion des erreurs
        console.error('Erreur lors de la connexion', error);
        if (error.response && error.response.status === 401) {
          this.errorMessage = this.$t('loginErrorMessageInvalidCredentials'); // Message pour identifiants invalides
        } else {
          this.errorMessage = this.$t('loginErrorMessage'); // Message générique d'erreur
        }
      }
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    capitalizeFirstLetter(text) {
      if (!text) return '';
      return text.charAt(0).toUpperCase() + text.slice(1);
    }
  },
}
</script>

  <style scoped>
  .login-page {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    background-color: #121212; /* Fond noir très sombre */
    padding: 0 20px;
    position: relative;
  }
  
  main {
    width: 100%;
    max-width: 400px;
    margin: auto;
  }
  
  .login-form-container {
    background-color: #1e1e1e; /* Fond sombre pour le conteneur du formulaire */
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); /* Ombre plus marquée pour le conteneur */
    text-align: center;
    margin: 0;
    max-height: 80vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .login-form-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6); /* Ombre plus marquée au survol */
  }
  
  .login-form-container h1 {
    margin-bottom: 20px;
    font-size: 1.5em;
    font-weight: 600;
    color: #ffffff; /* Texte blanc pour le titre */
    text-transform: capitalize; /* Première lettre en majuscule pour le titre */
  }
  
  .form-group {
    margin-bottom: 20px;
    position: relative;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
    color: #b0b0b0; /* Gris clair pour les labels */
  }
  
  .input-wrapper {
    position: relative;
  }
  
  .input-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    color: #b0b0b0; /* Couleur des icônes */
    filter: invert(100%) brightness(100%) contrast(100%);

  }
  
  input {
    width: 100%;
    padding: 10px 40px 10px 40px;
    border-radius: 6px;
    border: 1px solid #333; /* Bordure sombre pour les champs de saisie */
    background-color: #333; /* Fond sombre pour les champs de saisie */
    color: #ffffff; /* Texte blanc pour les champs de saisie */
    font-size: 14px;
    box-sizing: border-box;
  }
  
  .icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 1.2em;
    color: #ffffff; /* Icônes blanches pour la visibilité */
    cursor: pointer;
  }
  
  .submit-button {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 6px;
    background-color: #007bff; /* Bleu vif pour le bouton de soumission */
    color: #ffffff;
    font-size: 1.1em;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    font-family: 'Poppins', sans-serif;

  }
  
  .submit-button:hover {
    background-color: #0056b3; /* Bleu foncé au survol */
    transform: translateY(-2px);
  }
  
  .error-message {
    color: #e74c3c; /* Rouge pour les messages d'erreur */
    font-size: 0.875em;
    margin-top: 10px;
  }
  
  .watermark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); /* Fond noir semi-transparent pour la marque d'eau */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
  }
  
  .watermark-content {
    text-align: center;
    color: #007bff; /* Bleu pour le texte de la marque d'eau */
    font-size: 2em;
    font-weight: 600;
    opacity: 0.2;
  }
  
  @media (max-width: 768px) {
    .login-form-container {
      padding: 15px;
    }
  
    .login-form-container h1 {
      font-size: 1.25em;
    }
  }
  
  .fas {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
  }
  </style>
  