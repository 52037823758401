<template>
    <div class="legal-page">
      <header class="legal-header">
        <h1>{{ $t('privacyPolicyPage.title') }}</h1>
      </header>
      <section class="legal-content">
        <div class="legal-section">
          <h2>{{ $t('privacyPolicyPage.introTitle') }}</h2>
          <p>{{ $t('privacyPolicyPage.introText') }}</p>
          <h2>{{ $t('privacyPolicyPage.dataCollectionTitle') }}</h2>
          <p>{{ $t('privacyPolicyPage.dataCollectionText') }}</p>
          <h2>{{ $t('privacyPolicyPage.dataUsageTitle') }}</h2>
          <p>{{ $t('privacyPolicyPage.dataUsageText') }}</p>
          <h2>{{ $t('privacyPolicyPage.thirdPartyTitle') }}</h2>
          <p>{{ $t('privacyPolicyPage.thirdPartyText') }}</p>
          <h2>{{ $t('privacyPolicyPage.securityTitle') }}</h2>
          <p>{{ $t('privacyPolicyPage.securityText') }}</p>
          <h2>{{ $t('privacyPolicyPage.changesTitle') }}</h2>
          <p>{{ $t('privacyPolicyPage.changesText') }}</p>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: "PrivacyPolicyPage",
  };
  </script>
  
  <style scoped>
  .legal-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #111;
    padding: 20px;
    color: #fff;
  }
  
  .legal-header {
    margin-bottom: 20px;
  }
  
  .legal-header h1 {
    font-size: 2.5em;
    color: #fff;
    text-align: center;
  }
  
  .legal-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .legal-section {
    background-color: #222;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .legal-section h2 {
    font-size: 1.8em;
    color: #c00;
    margin-bottom: 10px;
  }
  
  .legal-section p {
    font-size: 1em;
    color: #ccc;
    line-height: 1.6;
  }
  </style>
  