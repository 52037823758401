<template>
  <div class="vehicles-container">
    <h1>{{ $t('availableVehicles') }}</h1>
    <div class="vehicles-grid">
      <div v-for="vehicle in vehicles" :key="vehicle.id" class="vehicle-card">
        <img :src="vehicle.image" alt="Vehicle Image" class="vehicle-image">
        <div class="vehicle-info">
          <h2>{{ capitalizeWords(vehicle.brand) }} {{ capitalizeWords(vehicle.model) }}</h2>
          <div class="info-grid">
            <div class="info-item">
              <img src="@/assets/pompe.png" alt="Fuel Icon" class="info-icon" />
              <p>{{ $t(`fuel_types.${vehicle.fuel_type}`) }}</p>
            </div>

            <div class="info-item">
              <img src="@/assets/siege.png" alt="Seats Icon" class="info-icon" />
              <p>{{ vehicle.seat_count }} {{ $t('seats') }}</p>
            </div>
            <div class="info-item">
              <img src="@/assets/porte.png" alt="Doors Icon" class="info-icon" />
              <p>{{ vehicle.door_count }} {{ $t('doors') }}</p>
            </div>
            <div class="info-item">
              <img src="@/assets/boite.png" alt="Transmission Icon" class="info-icon" />
              <p>{{ $t(`transmissions.${vehicle.transmission_type}`) }}</p>
            </div>
          </div>
          <p>{{ vehicle.description }}</p>
          <div class="color-info">
            <p style="margin-right: 5px;">{{ $t('color') }}</p>
            <div 
              class="color-box" 
              :style="{ backgroundColor: getColorCode(vehicle.color) }" 
              :title="$t(`colors.${vehicle.color.toLowerCase()}`) || $t('noColorSpecified')"
              @click="togglePopup($event)"
            >
              <!-- Use translation for color name -->
              <div class="color-popup">{{ $t(`colors.${vehicle.color.toLowerCase()}`) || $t('noColorSpecified') }}</div>
            </div>
          </div>
        </div>
        <!-- New Request a Quote Button -->
        <button class="request-quote-button" @click="toggleContactInfo(vehicle.id)">
          {{ $t('requestQuote') }}
        </button>
        <!-- Conditional Contact Info -->
        <div v-if="vehicle.showContactInfo" class="contact-info">
          <!-- WhatsApp Link with Preloaded Message -->
          <a :href="'https://wa.me/2120687350555?text=' + encodeURIComponent($t('whatsappMessage', { vehicleName: `${vehicle.brand} ${vehicle.model}`, vehicleColor: $t(`colors.${vehicle.color.toLowerCase()}`) }))" class="contact-icon whatsapp">
            <img src="@/assets/sociale.png" alt="WhatsApp Icon" class="contact-icon-image" />
          </a>
          <!-- Email Link with Preloaded Subject and Body -->
          <a :href="'mailto:info@scorpiocar.com?subject=' + encodeURIComponent($t('emailSubject', { vehicleName: `${vehicle.brand} ${vehicle.model}`, vehicleColor: $t(`colors.${vehicle.color.toLowerCase()}`) })) + '&body=' + encodeURIComponent($t('emailBody', { vehicleName: `${vehicle.brand} ${vehicle.model}`, vehicleColor: $t(`colors.${vehicle.color.toLowerCase()}`) }))" class="contact-icon email">
            <img src="@/assets/email.png" alt="Email Icon" class="contact-icon-image" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      vehicles: [],
      activeColorBox: null
    };
  },
  mounted() {
    axios.get('https://django.scorpiocar.com/api/vehicles/?format=json')
      .then(response => {
        // Initialize showContactInfo to false for each vehicle
        this.vehicles = response.data.map(vehicle => ({
          ...vehicle,
          showContactInfo: false
        }));
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  },
  methods: {
    getColorCode(color) {
      const colors = {
        noir: '#000000',
  blanc: '#FFFFFF',
  rouge: '#FF0000',
  vert: '#008000',
  bleu: '#0000FF',
  jaune: '#FFFF00',
  gris: '#808080',

  // Teintes de gris
  argent: '#C0C0C0',
  anthracite: '#404040',
  gris_fonce: '#505050',
  gris_clair: '#D3D3D3',

  // Teintes de bleu
  bleu_electrique: '#007FFF',
  bleu_nuit: '#191970',
  bleu_ciel: '#87CEEB',
  bleu_marine: '#000080',
  turquoise: '#40E0D0',

  // Teintes de rouge
  bordeaux: '#800000',
  rouge_cerise: '#DE3163',
  rouge_pompier: '#D2042D',
  carmin: '#960018',

  // Teintes de vert
  vert_fonce: '#006400',
  vert_olive: '#808000',
  vert_kaki: '#7C866B',
  vert_pomme: '#8DB600',

  // Autres couleurs
  orange: '#FFA500',
  marron: '#A52A2A',
  violet: '#800080',
  beige: '#F5F5DC',
  rose: '#FFC0CB',
  or: '#FFD700'
      };
      return colors[color.toLowerCase()] || '#FFFFFF';  // Returns white if color is not found
    },
    capitalizeWords(string) {
      if (!string) return '';
      return string.split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    },
    togglePopup(event) {
      const colorBox = event.currentTarget;
      if (this.activeColorBox && this.activeColorBox !== colorBox) {
        this.activeColorBox.classList.remove('active');
      }
      this.activeColorBox = colorBox.classList.contains('active') ? null : colorBox;
      colorBox.classList.toggle('active');
    },
    toggleContactInfo(vehicleId) {
      this.vehicles = this.vehicles.map(vehicle => ({
        ...vehicle,
        showContactInfo: vehicle.id === vehicleId ? !vehicle.showContactInfo : vehicle.showContactInfo
      }));
    },
    getWhatsappMessage(vehicle) {
      return this.$t('whatsappMessage', { vehicleName: `${vehicle.brand} ${vehicle.model}` });
    },
    getEmailSubject(vehicle) {
      return this.$t('emailSubject', { vehicleName: `${vehicle.brand} ${vehicle.model}` });
    },
    getEmailBody(vehicle) {
      return this.$t('emailBody', { vehicleName: `${vehicle.brand} ${vehicle.model}` });
    }
  }
};
</script>

<style scoped>
.vehicles-container {
  padding: 20px;
  background-color: #111; /* Fond noir pour le conteneur */
  color: #fff; /* Texte blanc pour le contraste */
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #fff; /* Texte blanc pour le titre principal */
}

.vehicles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.vehicle-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #444; /* Bordure sombre pour les cartes */
  border-radius: 8px;
  overflow: hidden;
  background-color: #222; /* Fond sombre pour les cartes */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Ombre plus marquée */
  transition: transform 0.3s;
}

.vehicle-card:hover {
  transform: translateY(-5px);
}

.vehicle-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.vehicle-info {
  padding: 15px;
  text-align: center;
}

.vehicle-info h2 {
  margin: 10px 0;
  font-size: 1.2em;
  color: #c00; /* Rouge pour les sous-titres */
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 10px;
}

.info-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
}

.contact-icon.email{  filter: invert(100%) brightness(100%) contrast(100%);
}

.info-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  filter: invert(100%) brightness(100%) contrast(100%);
}

.vehicle-info p {
  margin: 5px 0;
  color: #ccc; /* Texte gris clair pour une meilleure lisibilité sur fond sombre */
}

.color-info {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.color-box {
  width: 30px;
  height: 30px;
  border: 1px solid #444; /* Bordure sombre pour les boîtes de couleur */
  margin-right: 10px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  transition: transform 0.2s ease;
}

.color-popup {
  display: none;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  white-space: nowrap;
  left: 50%;
  top: -40px;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
}

.color-box.active .color-popup {
  display: block;
  opacity: 1;
}

.contact-info {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.contact-icon {
  margin: 0 10px;
  text-decoration: none;
  transition: transform 0.3s, opacity 0.3s;
}

.contact-icon.whatsapp:hover,
.contact-icon.email:hover {
  transform: scale(1.1);
  opacity: 0.8;
}

.contact-icon-image {
  width: 30px;
  height: 30px;
  transition: transform 0.3s;
}

.request-quote-button {
  background-color: #c00; /* Rouge pour le bouton de demande de devis */
  color: #fff;
  border: none;
  padding: 12px 24px;
  font-size: 1.1em;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s, transform 0.3s;
  font-family: 'Poppins', sans-serif;
}

.request-quote-button:hover {
  background-color: #a00; /* Rouge foncé pour l'état survolé du bouton */
  transform: translateY(-3px);
}

@media (max-width: 600px) {
  .request-quote-button {
    font-size: 1.2em;
    padding: 14px 28px;
  }

  .contact-icon-image {
    width: 40px;
    height: 40px;
  }

  .color-box {
    width: 40px;
    height: 40px;
  }
}
</style>
