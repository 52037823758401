import { createRouter, createWebHistory } from 'vue-router';
// Importation des composants
import HomePage from '../components/HomePage.vue';
import ContactPage from '../components/ContactPage.vue';
import VehicleList from '../components/VehicleList.vue';
import AboutPage from '../components/AboutPage.vue';
import AdminVehicles from '../components/AdminVehicles.vue';
import VehicleForm from '../components/VehicleForm.vue';
import LoginPage from '../components/LoginPage.vue'; // Importer la page de connexion

// Nouveaux composants pour les pages légales
import PrivacyPolicy from '../components/PrivacyPolicyPage.vue';
import LegalNotice from '../components/LegalMentionsPage.vue';
import TermsOfService from '../components/TermsOfServicePage.vue';


const routes = [
  // Routes publiques
  { path: '/', component: HomePage, meta: { title: 'Accueil' } },
  { path: '/vehicles', component: VehicleList, meta: { title: 'Liste des Véhicules' } },
  { path: '/about', component: AboutPage, meta: { title: 'À Propos' } },
  { path: '/contact', component: ContactPage, meta: { title: 'Contact' } },
  { path: '/privacy-policy', component: PrivacyPolicy, meta: { title: 'privacyPolicy' } },
  { path: '/legal-notice', component: LegalNotice, meta: { title: 'legalNotice' } },
  { path: '/terms-of-service', component: TermsOfService, meta: { title: 'termsOfService' } },
  { path: '/ifures', component: LoginPage, meta: { title: 'Connexion' } }, // Nouvelle route pour la connexion


  // Routes d'administration
  {
    path: '/admin/vehicles',
    name: 'AdminVehicles',
    component: AdminVehicles,
    meta: { title: 'Administration des Véhicules', requiresAuth: true }
  },
  {
    path: '/admin/vehicles/:action?/:id?',
    name: 'VehicleForm',
    component: VehicleForm,
    props: route => ({
      action: route.params.action,
      id: route.params.id
    }),
    meta: { title: 'Gestion des Véhicules', requiresAuth: true }
  },
  // Route pour les pages non trouvées
  { path: '/:pathMatch(.*)*', redirect: '/' }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// Garde de route pour l'authentification
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('access_token');
  
  // Rediriger vers la page de connexion si l'utilisateur n'est pas authentifié et que la route nécessite une authentification
  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/ifures');
  } else {
    next();
  }
});

export default router;
