<template>
  <div class="vehicles-container">
    <header>
      <h1>{{ $t("vehicleManagement") }}</h1>
      <button @click="showForm('create')" class="button add-vehicle">
        <img src="@/assets/plus.png" alt="Ajouter un Nouveau Véhicule" class="white-icon" />
      </button>
    </header>

    <section class="vehicles-grid">
      <div v-for="vehicle in vehicles" :key="vehicle.id" class="vehicle-card">
        <img v-if="vehicle.image" :src="vehicle.image" alt="Image du Véhicule" class="vehicle-image" />
        <div class="vehicle-info">
          <h2>{{ capitalizeWords(vehicle.brand) }} {{ capitalizeWords(vehicle.model) }}</h2>
          <div class="info-grid">
            <div class="info-item">
              <img src="@/assets/pompe.png" alt="Fuel Icon" class="info-icon" />
              <p>{{ $t(`fuel_types.${vehicle.fuel_type}`) }}</p>
            </div>
            <div class="info-item">
              <img src="@/assets/siege.png" alt="Seats Icon" class="info-icon" />
              <p>{{ vehicle.seat_count }} {{ $t("seats") }}</p>
            </div>
            <div class="info-item">
              <img src="@/assets/porte.png" alt="Doors Icon" class="info-icon" />
              <p>{{ vehicle.door_count }} {{ $t("doors") }}</p>
            </div>
            <div class="info-item">
              <img src="@/assets/boite.png" alt="Transmission Icon" class="info-icon" />
              <p>{{ $t(`transmissions.${vehicle.transmission_type}`) }}</p>
            </div>
          </div>
          <p><strong>{{ $t("color") }}:</strong></p>
          <div class="color-info">
            <div
              class="color-box"
              :style="{ backgroundColor: getColorCode(vehicle.color) }"
              :title="$t(`colors.${vehicle.color.toLowerCase()}`) || $t('noColorSpecified')"
              @click="togglePopup($event)"
            >
              <div class="color-popup">{{ $t(`colors.${vehicle.color.toLowerCase()}`) || $t('noColorSpecified') }}</div>
            </div>
          </div>
          <div class="vehicle-actions">
            <button @click="showForm('edit', vehicle)" class="button edit-button">
              <img src="@/assets/edit.png" alt="Modifier" />
            </button>
            <button @click="confirmDelete(vehicle.id)" class="button delete-button">
              <img src="@/assets/trash.png" alt="Supprimer" />
            </button>
          </div>
        </div>
      </div>
    </section>

    <div v-if="showFormVisible" class="modal" :style="{ zIndex: showDeleteModal ? 1001 : 1000 }">
      <div class="modal-content">
        <form @submit.prevent="submitForm">
          <div class="form-group">
            <label for="brand">{{ $t('formModal.brand') }} <span v-if="!isEdit" class="required">*</span></label>
            <input v-model="vehicle.brand" id="brand" :required="!isEdit" />
          </div>
          <div class="form-group">
            <label for="model">{{ $t('formModal.model') }} <span v-if="!isEdit" class="required">*</span></label>
            <input v-model="vehicle.model" id="model" :required="!isEdit" />
          </div>
          <div class="form-group">
            <label for="fuel_type">{{ $t('formModal.fuelType') }} <span v-if="!isEdit" class="required">*</span></label>
            <select v-model="vehicle.fuel_type" id="fuel_type" :required="!isEdit">
              <option value="Diesel">{{ $t('formModal.diesel') }}</option>
              <option value="Gasoline">{{ $t('formModal.gasoline') }}</option>
              <option value="Electric">{{ $t('formModal.electric') }}</option>
              <option value="Hybrid">{{ $t('formModal.hybrid') }}</option>
              <option value="Gas">{{ $t('formModal.gas') }}</option>
              <option value="Hydrogène">{{ $t('formModal.hydrogen') }}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="seat_count">{{ $t('formModal.seatCount') }} <span v-if="!isEdit" class="required">*</span></label>
            <input v-model="vehicle.seat_count" id="seat_count" type="number" :required="!isEdit" />
          </div>
          <div class="form-group">
            <label for="door_count">{{ $t('formModal.doorCount') }} <span v-if="!isEdit" class="required">*</span></label>
            <input v-model="vehicle.door_count" id="door_count" type="number" :required="!isEdit" />
          </div>
          <div class="form-group">
            <label for="transmission_type">{{ $t('formModal.transmissionType') }} <span v-if="!isEdit" class="required">*</span></label>
            <select v-model="vehicle.transmission_type" id="transmission_type" :required="!isEdit">
              <option value="Manuelle">{{ $t('formModal.manual') }}</option>
              <option value="Automatique">{{ $t('formModal.automatic') }}</option>
              <option value="Semi-Automatique">{{ $t('formModal.semiAutomatic') }}</option>
              <option value="CVT">{{ $t('formModal.cvt') }}</option>
              <option value="Double Embrayage">{{ $t('formModal.dualClutch') }}</option>
              <option value="Autre">{{ $t('formModal.other') }}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="color">{{ $t('formModal.color') }} <span v-if="!isEdit" class="required">*</span></label>
            <div class="custom-select-wrapper">
              <select v-model="vehicle.color" id="color" :required="!isEdit">
                <option v-for="(label, value) in colorOptions" :key="value" :value="value">
                  {{ $t(`formModal.colorOptions.${value}`) }}
                </option>
              </select>
              <div class="custom-select">
                <div class="custom-select-value">
                  <div class="color-swatch" :style="{ backgroundColor: getColorCode(vehicle.color) }"></div>
                  <span v-if="vehicle.color">
                    {{ $t(`colors.${vehicle.color.toLowerCase()}`) }}
                  </span>
                  <span v-else>
                    {{ $t('formModal.noColorSpecified') }}
                  </span>
                </div>
                <div class="custom-select-options">
                  <div v-for="(label, value) in colorOptions" :key="value" @click="selectColor(value)">
                    <div class="color-swatch" :style="{ backgroundColor: getColorCode(value) }"></div>
                    <span>{{ $t(`formModal.colorOptions.${value}`) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="image">{{ $t('formModal.image') }}</label> 
            <input type="file" @change="onFileChange" id="image" accept="image/*"/>
          </div>
          <div class="form-actions">
            <button type="submit" class="button primary">{{ isEdit ? $t('formModal.edit') : $t('formModal.add') }}</button>
            <button @click="cancelForm" class="button secondary">{{ $t('formModal.cancel') }}</button>
          </div>
        </form>
        <p v-if="errorMessage" class="error">{{ $t('formModal.errorMessage') }}</p>
      </div>
    </div>

    <!-- Delete Confirmation Modal -->
    <div v-if="showDeleteModal" class="modal" style="z-index: 1002;">
      <div class="modal-content">
        <p>{{ $t('formModal.deleteConfirmation') }}</p>
        <div class="form-actions">
          <button @click="deleteVehicle" class="button danger">{{ $t('formModal.confirm') }}</button>
          <button @click="closeDeleteModal" class="button secondary">{{ $t('formModal.cancel') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/axios";
import { ref, onMounted } from "vue";

export default {
  setup() {
    const vehicles = ref([]);
    const vehicle = ref({
      id: null,
      brand: "",
      model: "",
      fuel_type: "",
      seat_count: 0,
      door_count: 0,
      transmission_type: "",
      color: "",
      image: null,
    });
    const imageFile = ref(null);
    const showFormVisible = ref(false);
    const isEdit = ref(false);
    const showDeleteModal = ref(false);
    const vehicleToDelete = ref(null);
    const errorMessage = ref("");
    const activeColorBox = ref(null);

    const colorOptions = {
  noir: '#000000',
  blanc: '#FFFFFF',
  rouge: '#FF0000',
  vert: '#008000',
  bleu: '#0000FF',
  jaune: '#FFFF00',
  gris: '#808080',

  // Teintes de gris
  argent: '#C0C0C0',
  anthracite: '#404040',
  gris_fonce: '#505050',
  gris_clair: '#D3D3D3',

  // Teintes de bleu
  bleu_electrique: '#007FFF',
  bleu_nuit: '#191970',
  bleu_ciel: '#87CEEB',
  bleu_marine: '#000080',
  turquoise: '#40E0D0',

  // Teintes de rouge
  bordeaux: '#800000',
  rouge_cerise: '#DE3163',
  rouge_pompier: '#D2042D',
  carmin: '#960018',

  // Teintes de vert
  vert_fonce: '#006400',
  vert_olive: '#808000',
  vert_kaki: '#7C866B',
  vert_pomme: '#8DB600',

  // Autres couleurs
  orange: '#FFA500',
  marron: '#A52A2A',
  violet: '#800080',
  beige: '#F5F5DC',
  rose: '#FFC0CB',
  or: '#FFD700'
    };

    const fetchVehicles = async () => {
      try {
        const response = await apiClient.get("/vehicles/");
        vehicles.value = response.data;
      } catch (error) {
        console.error("Erreur lors de la récupération des véhicules", error);
        errorMessage.value = "Erreur lors de la récupération des véhicules.";
      }
    };

    const showForm = (mode, vehicleToEdit = null) => {
      isEdit.value = mode === "edit";
      if (vehicleToEdit) {
        Object.assign(vehicle.value, vehicleToEdit);
      } else {
        vehicle.value = {
          id: null,
          brand: "",
          model: "",
          fuel_type: "",
          seat_count: 0,
          door_count: 0,
          transmission_type: "",
          color: "",
          image: null,
        };
      }
      showFormVisible.value = true;
    };

    const onFileChange = (event) => {
      const files = event.target.files;
      imageFile.value = files.length ? files[0] : null;
    };

    const submitForm = async () => {
      try {
        const formData = new FormData();
        formData.append("brand", vehicle.value.brand);
        formData.append("model", vehicle.value.model);
        formData.append("fuel_type", vehicle.value.fuel_type);
        formData.append("seat_count", vehicle.value.seat_count);
        formData.append("door_count", vehicle.value.door_count);
        formData.append("transmission_type", vehicle.value.transmission_type);
        formData.append("color", vehicle.value.color);
        if (imageFile.value) {
          formData.append("image", imageFile.value);
        }

        if (isEdit.value) {
          await apiClient.put(`/vehicles/${vehicle.value.id}/`, formData);
        } else {
          await apiClient.post("/vehicles/", formData);
        }

        await fetchVehicles();
        cancelForm();
      } catch (error) {
        console.error("Erreur lors de la soumission du formulaire", error);
        errorMessage.value = "Erreur lors de la soumission du formulaire.";
      }
    };

    const cancelForm = () => {
      showFormVisible.value = false;
      errorMessage.value = "";
    };

    const confirmDelete = (id) => {
      vehicleToDelete.value = id;
      showDeleteModal.value = true;
    };

    const deleteVehicle = async () => {
      try {
        await apiClient.delete(`/vehicles/${vehicleToDelete.value}/`);
        fetchVehicles();
        closeDeleteModal();
      } catch (error) {
        console.error("Erreur lors de la suppression du véhicule", error);
        errorMessage.value = "Erreur lors de la suppression du véhicule.";
      }
    };

    const closeDeleteModal = () => {
      showDeleteModal.value = false;
      vehicleToDelete.value = null;
    };

    const getColorCode = (color) => {
      const colorCodes = {
        noir: '#000000',
  blanc: '#FFFFFF',
  rouge: '#FF0000',
  vert: '#008000',
  bleu: '#0000FF',
  jaune: '#FFFF00',
  gris: '#808080',

  // Teintes de gris
  argent: '#C0C0C0',
  anthracite: '#404040',
  gris_fonce: '#505050',
  gris_clair: '#D3D3D3',

  // Teintes de bleu
  bleu_electrique: '#007FFF',
  bleu_nuit: '#191970',
  bleu_ciel: '#87CEEB',
  bleu_marine: '#000080',
  turquoise: '#40E0D0',

  // Teintes de rouge
  bordeaux: '#800000',
  rouge_cerise: '#DE3163',
  rouge_pompier: '#D2042D',
  carmin: '#960018',

  // Teintes de vert
  vert_fonce: '#006400',
  vert_olive: '#808000',
  vert_kaki: '#7C866B',
  vert_pomme: '#8DB600',

  // Autres couleurs
  orange: '#FFA500',
  marron: '#A52A2A',
  violet: '#800080',
  beige: '#F5F5DC',
  rose: '#FFC0CB',
  or: '#FFD700'
      };
      return colorCodes[color.toLowerCase()] || "#FFFFFF";
    };

    const capitalizeWords = (str) => {
      return str
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    };

    const togglePopup = (event) => {
      const colorBox = event.currentTarget;
      if (activeColorBox.value && activeColorBox.value !== colorBox) {
        activeColorBox.value.classList.remove('active');
      }
      activeColorBox.value = colorBox;
      activeColorBox.value.classList.toggle('active');
    };

    onMounted(fetchVehicles);

    return {
      vehicles,
      vehicle,
      colorOptions,
      showFormVisible,
      isEdit,
      showDeleteModal,
      errorMessage,
      showForm,
      cancelForm,
      onFileChange,
      submitForm,
      confirmDelete,
      deleteVehicle,
      closeDeleteModal,
      getColorCode,
      capitalizeWords,
      togglePopup,
    };
  },
};
</script>

  
<style scoped>
.vehicles-container {
  padding: 20px;
  background-color: #111; /* Fond noir pour le conteneur */
  color: #fff; /* Texte blanc pour le contraste */
}

.button{
  font-family: 'Poppins', sans-serif;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #fff; /* Texte blanc pour le titre */
}

.vehicles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.vehicle-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #444; /* Bordure sombre */
  border-radius: 12px;
  overflow: hidden;
  background-color: #222; /* Fond sombre pour les cartes */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Ombre plus marquée */
  transition: transform 0.3s;
}

.required {
color: red; 
}

.vehicle-card:hover {
  transform: translateY(-5px);
}

.vehicle-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid #444; /* Bordure sombre sous l'image */
}

.vehicle-info {
  padding: 15px;
  text-align: center;
}

.vehicle-info h2 {
  margin: 10px 0;
  font-size: 1.2em;
  color: #c00; /* Rouge pour les sous-titres */
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 10px;
}

.info-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
}

.info-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  filter: invert(100%); /* Inverse les couleurs des icônes */
}

.vehicle-info p {
  margin: 5px 0;
  color: #ccc; /* Texte gris clair pour une meilleure lisibilité sur fond sombre */
}

.color-info {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.color-box {
  width: 30px;
  height: 30px;
  border: 1px solid #444; /* Bordure sombre */
  margin-right: 10px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  transition: transform 0.2s ease;
}

.color-popup {
  display: none;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  white-space: nowrap;
  left: 50%;
  top: -40px;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
}

.color-box.active .color-popup {
  display: block;
  opacity: 1;
}

.contact-info {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.vehicle-actions {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.button {
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s, transform 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button img {
  width: 20px;
  height: 20px;
}

.button.add-vehicle {
  background-color: #007bff; /* Bleu pour le bouton Ajouter un véhicule */
  color: #fff;
  margin-bottom: 10px;
}

.button.add-vehicle:hover {
  background-color: #0056b3; /* Bleu foncé au survol */
  transform: translateY(-3px);
}

.button.edit-button {
  background-color: #ff7907; /* Orange pour le bouton Éditer */
  color: #fff;
  margin-top: 3px;
}

.button.edit-button:hover {
  background-color: #e0a800; /* Jaune foncé au survol */
}

.button.delete-button {
  background-color: #e74c3c; /* Rouge vif pour le bouton Supprimer */
  color: #fff;
  margin-top: 3px;
}

.button.delete-button:hover {
  background-color: #c0392b; /* Rouge foncé au survol */
}

.button.danger {
  background-color: #e74c3c; /* Rouge vif pour confirmer la suppression */
  color: #fff;
}

.button.danger:hover {
  background-color: #c0392b; /* Rouge plus foncé au survol */
}

.button.secondary {
  background-color: #6c757d; /* Gris pour le bouton Annuler */
  color: #fff;
}

.button.secondary:hover {
  background-color: #5a6268; /* Gris plus foncé au survol */
}

.button.primary {
  background-color: #007bff; /* Bleu pour le bouton principal */
  color: #fff; /* Texte en blanc */
}

.button.primary:hover {
  background-color: #0056b3; /* Bleu foncé au survol */
}

/* Styles de base de la modale */
.modal {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: 
 rgba(0, 0, 0, 0.5); 
}

/* Style général pour le contenu de la modale */
.modal-content {
  background: #222; /* Fond sombre pour le contenu de la modale */
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 600px;
  color: #fff; /* Texte blanc pour le contenu de la modale */
}

.modal-content form {
  /* Ensure form elements fit within the modal */
  display: flex;
  flex-direction: column; 
}

.form-group {
  /* Give each form group a bit of breathing room */
  margin-bottom: 15px;
}

.form-group input,
.form-group select {
  /* Standardize field sizes across types */
  width: 100%; /* Ensure inputs span the full width of their container */
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #444;
  background-color: #333;
  color: #fff;
  box-sizing: border-box; /* Prevent padding from affecting overall width */
}

.custom-select-wrapper {
  /* Match the styling of other form elements */
  width: 100%; 
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #444;
  background-color: #333;
  color: #fff;
  box-sizing: border-box; 
}

/* Styles pour les champs de formulaire dans la modale */
.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #ccc; /* Texte gris clair pour les labels */
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #444; /* Bordure sombre */
  background-color: #333; /* Fond sombre pour les champs */
  color: #fff; /* Texte blanc dans les champs */
  transition: border-color 0.3s;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #007bff; /* Bordure bleue au focus */
  outline: none;
}

.form-group input[type="file"] {
  padding: 5px;
  border: 1px solid #444; /* Bordure sombre */
  border-radius: 6px;
  background-color: #333; /* Fond sombre pour les fichiers */
}

.form-group input[type="file"]::-webkit-file-upload-button {
  background: #007bff; /* Bleu pour le bouton de téléchargement de fichier */
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1em;
}

.form-group input[type="file"]::-webkit-file-upload-button:hover {
  background: #0056b3; /* Bleu foncé au survol */
}

.image-preview {
  margin-top: 10px;
  max-width: 100%;
  height: auto;
  border-radius: 6px;
  border: 1px solid #444; /* Bordure sombre */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Ombre plus marquée */
}

.form-actions {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.custom-select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.custom-select {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #444; /* Bordure sombre */
  border-radius: 6px;
  cursor: pointer;
  background: #333; /* Fond sombre pour le sélecteur personnalisé */
}

.custom-select-value {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 2px solid transparent;
  border-radius: 6px;
  transition: border-color 0.2s;
  color: #fff; /* Texte blanc dans le sélecteur personnalisé */
}

.custom-select-value:hover {
  border-color: #007bff; /* Bordure bleue au survol */
}

.color-swatch {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 10px;
}

.custom-select-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #444; /* Bordure sombre */
  border-radius: 6px;
  background: #333; /* Fond sombre pour les options */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  display: none;
  flex-direction: column;
  z-index: 10;
  max-height: 200px; /* Limite la hauteur pour éviter les débordements */
  overflow-y: auto; /* Ajoute une barre de défilement si nécessaire */
}

.custom-select-options div {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  color: #fff; /* Texte blanc pour les options */
}

.custom-select-options div:hover {
  background-color: #444; /* Fond sombre au survol */
}

.custom-select-options .color-swatch {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 10px;
}

.custom-select-options.show {
  display: flex;
}

/* Media query pour les écrans plus grands (tablettes et ordinateurs de bureau) */
@media (min-width: 768px) {
  .modal {
    position: fixed; /* Fixe la modale au centre de l'écran */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Fond semi-transparent */
  }

  .modal-content {
    /* Vos styles pour le contenu de la modale sur grand écran */
    max-width: 600px; /* Limite la largeur maximale */
  }
}

/* Media query pour les écrans plus petits (mobiles) */
@media (max-width: 767px) {
  .modal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%; 
    max-height: 90vh; 
    overflow-y: auto;
  }
}

.modal-content {
  background: #222; 
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 90%; /* Updated for better responsiveness */
  max-width: 600px; 
  color: #fff;
  border-radius: 12px; /* Added for rounded corners */
  max-height: 80vh;  /* Set maximum height for content on small screens */
  overflow-y: auto;  /* Add vertical scroll if content overflows */
}

.custom-select-options {
  max-height: 200px;
  overflow-y: auto; 
}

  /* Styles pour les champs de formulaire sur mobile */
  .form-group input,
  .form-group select {
    font-size: 16px; /* Agrandit la taille de la police */
    padding: 12px; /* Augmente l'espace intérieur */
  }

/* Styles Responsifs */
@media (max-width: 768px) {
  .custom-select {
    font-size: 14px; /* Réduit la taille du texte pour les petits écrans */
  }

  .color-swatch {
    width: 15px; /* Réduit la taille du carré pour les petits écrans */
    height: 15px;
  }

  .custom-select-value {
    padding: 8px;
  }
}
</style>

