<template>
    <div class="legal-page">
      <header class="legal-header">
        <h1>{{ $t('legalMentionsPage.title') }}</h1>
      </header>
      <section class="legal-content">
        <div class="legal-section">
          <h2>{{ $t('legalMentionsPage.ownerTitle') }}</h2>
          <p>{{ $t('legalMentionsPage.ownerText') }}</p>
          <h2>{{ $t('legalMentionsPage.contactTitle') }}</h2>
          <p v-text="$t('legalMentionsPage.contactText').replace('[EMAIL]', email)"></p>
          <h2>{{ $t('legalMentionsPage.hostingTitle') }}</h2>
          <p>{{ $t('legalMentionsPage.hostingText') }}</p>
          <h2>{{ $t('legalMentionsPage.intellectualPropertyTitle') }}</h2>
          <p>
          {{ $t('legalMentionsPage.intellectualPropertyText') }}
          <span class="author-name">Disarray</span><span>.</span>
            </p>
        <h2>{{ $t('legalMentionsPage.liabilityTitle') }}</h2>
          <p>{{ $t('legalMentionsPage.liabilityText') }}</p>
        </div>
      </section>
    </div>
  </template>
  
  <script>
export default {
  name: "LegalMentionsPage",
  data() {
    return {
      email: 'info@scorpiocar.com',
      contactText: this.$t('legalMentionsPage.contactText')
    };
  },
  computed: {
    formattedContactText() {
      return this.contactText.replace('[EMAIL]', this.email);
    }
  }
};
</script>
  
  <style scoped>
  .legal-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #111;
    padding: 20px;
    color: #fff;
  }

  .author-name {
  font-family: 'Ramadhan', sans-serif;
  font-weight: bold;
  color: #C8102E;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}
  
  .legal-header {
    margin-bottom: 20px;
  }
  
  .legal-header h1 {
    font-size: 2.5em;
    color: #fff;
    text-align: center;
  }
  
  .legal-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .legal-section {
    background-color: #222;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .legal-section h2 {
    font-size: 1.8em;
    color: #c00;
    margin-bottom: 10px;
  }
  
  .legal-section p {
    font-size: 1em;
    color: #ccc;
    line-height: 1.6;
  }
  </style>
  