<template>
  <div class="map-container" ref="mapContainer"></div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { onMounted, onUnmounted, ref } from 'vue';

// Fixer le chemin des icônes Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: require('@/assets/leaflet/marker2.png'),
  iconRetinaUrl: require('@/assets/leaflet/marker2.png'),
  shadowUrl: require('@/assets/leaflet/marker-shadow.png')
});

export default {
  setup() {
    const mapContainer = ref(null);
    let map = null;
    let marker = null;

    // Coordonnées corrigées
    const lat = 33.59212;
    const lng = -7.50330;

    // Flags pour suivre l'état du popup
    let isPopupOpen = false;

    // Fonction de redimensionnement pour s'assurer que la carte est mise à jour
    const resizeListener = () => {
      if (map) {
        map.invalidateSize();  // Redessine la carte avec la nouvelle taille
        map.setView([lat, lng]);  // Recentrer la carte sur le marqueur
        if (marker && isPopupOpen) {
          marker.openPopup();  // Réouvre le popup pour s'assurer qu'il est correctement positionné
        }
      }
    };

    // Fonction d'initialisation de la carte
    const initMap = () => {
      if (mapContainer.value) {
        // Création de la carte
        map = L.map(mapContainer.value, {
          zoomControl: false,
          center: [lat, lng],
          zoom: 13
        });

        // Ajout de la couche de tuiles
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '© OpenStreetMap contributors',
        }).addTo(map);

        // Création et ajout du marqueur
        marker = L.marker([lat, lng]).addTo(map);

        // Création et ajout de la popup
        const popup = L.popup({
          maxWidth: 200,
          minWidth: 100,
          keepInView: true,
          autoPan: true, // Assurez-vous que le popup déplace la carte pour rester visible
          closeButton: true,
          autoClose: false,
          className: 'custom-popup', // Ajout de la classe personnalisée
        })
          .setLatLng([lat, lng])
          .setContent('106 Lot Ennahda MG 18');

        marker.bindPopup(popup);

        // Ouverture du popup lorsque la carte est prête
        map.whenReady(() => {
          map.setView([lat, lng]); // Recentrer la carte après le chargement
          marker.openPopup();
          isPopupOpen = true;
          map.invalidateSize();  // Assurez-vous que la carte est correctement affichée
        });

        // Écouteur d'événement pour redimensionner la carte
        window.addEventListener('resize', resizeListener);

        // Ajout du contrôle de zoom
        L.control.zoom({ position: 'topright' }).addTo(map);

        // Gestion des clics sur la carte pour ouvrir la navigation
        map.on('click', () => {
          const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
          const isAndroid = /Android/.test(navigator.userAgent);

          if (isIOS) {
            window.open(`https://maps.apple.com/?daddr=${lat},${lng}`, '_blank');
          } else if (isAndroid) {
            window.open(`google.navigation:q=${lat},${lng}`, '_blank');
          } else {
            window.open(`https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`, '_blank');
          }
        });

        // Limiter les actions de popup lors du déplacement
        map.on('movestart', () => {
          if (isPopupOpen) {
            marker.closePopup();
            isPopupOpen = false;
          }
        });

        map.on('moveend', () => {
          if (!isPopupOpen) {
            marker.openPopup();
            isPopupOpen = true;
          }
        });
      }
    };

    // Initialisation de la carte à l'initialisation du composant
    onMounted(() => {
      initMap();
    });

    // Nettoyage lors du démontage du composant
    onUnmounted(() => {
      if (map) {
        map.remove();
        map = null;
      }
      window.removeEventListener('resize', resizeListener);
    });

    return { mapContainer };
  }
};
</script>

<style>
@import 'leaflet/dist/leaflet.css';

.map-container {
  height: 400px;
  width: 100%;
  overflow: hidden; /* Prévenir les problèmes de débordement */
  position: relative; /* Aide à maintenir la mise en page de la carte stable */
}

.custom-popup {
  font-size: 14px; /* Ajustez la taille de la police si nécessaire */
  text-align: center; /* Centrer le texte */
  font-family: 'Poppins', sans-serif;
  white-space: nowrap; /* Empêche le retour à la ligne */
}
</style>